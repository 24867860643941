import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react'

import './App.css';
import Dailies from './Dailies';

const authFields = [
  {
    type: "username",
    label: "Email Address *",
    placeholder: "Email",
    required: true,
  },
  {
    type: "password",
    label: "Password *",
    placeholder: "Password",
    required: true,
  },
];

function App() {
  const [loggedInState, setLoggedInState] = useState(undefined);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => setLoggedInState(true))
      .catch(e => setLoggedInState(false))

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setLoggedInState(true);
          break;
        case 'signOut':
        default:
          setLoggedInState(false);
          break;
      }
    });
  }, [])

  return loggedInState !== undefined && <div className='background'>
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp slot="sign-up" formFields={authFields} />
      <div className='signout-container'><AmplifySignOut /></div>
      {loggedInState && <Dailies />}
    </AmplifyAuthenticator>
  </div>;
}

export default App;
