/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDaily = /* GraphQL */ `
  mutation CreateDaily(
    $input: CreateDailyInput!
    $condition: ModelDailyConditionInput
  ) {
    createDaily(input: $input, condition: $condition) {
      id
      day
      paragraphs {
        items {
          id
          dailyId
          body
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateDaily = /* GraphQL */ `
  mutation UpdateDaily(
    $input: UpdateDailyInput!
    $condition: ModelDailyConditionInput
  ) {
    updateDaily(input: $input, condition: $condition) {
      id
      day
      paragraphs {
        items {
          id
          dailyId
          body
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteDaily = /* GraphQL */ `
  mutation DeleteDaily(
    $input: DeleteDailyInput!
    $condition: ModelDailyConditionInput
  ) {
    deleteDaily(input: $input, condition: $condition) {
      id
      day
      paragraphs {
        items {
          id
          dailyId
          body
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createParagraph = /* GraphQL */ `
  mutation CreateParagraph(
    $input: CreateParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    createParagraph(input: $input, condition: $condition) {
      id
      dailyId
      body
      owner
    }
  }
`;
export const updateParagraph = /* GraphQL */ `
  mutation UpdateParagraph(
    $input: UpdateParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    updateParagraph(input: $input, condition: $condition) {
      id
      dailyId
      body
      owner
    }
  }
`;
export const deleteParagraph = /* GraphQL */ `
  mutation DeleteParagraph(
    $input: DeleteParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    deleteParagraph(input: $input, condition: $condition) {
      id
      dailyId
      body
      owner
    }
  }
`;
