/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDaily = /* GraphQL */ `
  query GetDaily($id: ID!) {
    getDaily(id: $id) {
      id
      day
      paragraphs {
        items {
          id
          dailyId
          body
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listDailys = /* GraphQL */ `
  query ListDailys(
    $filter: ModelDailyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        day
        paragraphs {
          items {
            id
            dailyId
            body
            owner
          }
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getParagraph = /* GraphQL */ `
  query GetParagraph($id: ID!) {
    getParagraph(id: $id) {
      id
      dailyId
      body
      owner
    }
  }
`;
export const listParagraphs = /* GraphQL */ `
  query ListParagraphs(
    $filter: ModelParagraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParagraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dailyId
        body
        owner
      }
      nextToken
    }
  }
`;
