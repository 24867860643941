import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createDaily } from './graphql/mutations';
import { listDailys } from './graphql/queries';
import moment from 'moment';
import Paragraph from './Paragraph';

function Dailies() {
  const [dailiesState, setDailiesState] = useState([]);

  useEffect(() => {
    fetchDailies();
  }, []);

  async function fetchDailies() {
    const dailiesData = await API.graphql(graphqlOperation(listDailys));
    const dailies = dailiesData.data.listDailys.items;
    const today = moment().format('YYYY-MM-DD');
    if (!dailies.some(d => d.day === today)) {
      const dailyData = await API.graphql(graphqlOperation(createDaily, {
        input: { day: today }
      }))
      dailies.push(dailyData.data.createDaily);
    }
    dailies.sort((a, b) => new Date(b.day) - new Date(a.day));
    if (dailies[0].paragraphs.items.length === 0) {
      dailies[0].paragraphs.items.push({ id: null, body: "" });
    }
    setDailiesState(dailies.filter((d, i) => i === 0 || d.paragraphs.items.some(p => p.body)));
  }

  return dailiesState.length > 0 && <div className='center-column'>
    <div className='title'>Journal</div>
    <div>
      {dailiesState.map((daily, di) => <div key={daily.day}>
        <div className='date'>{daily.day}</div>
        {daily.paragraphs.items.map((paragraph, pi) =>
          <Paragraph
            key={pi}
            dailyId={daily.id}
            id={paragraph.id}
            body={paragraph.body}
            old={di !== 0}
          />
        )}
      </div>)}
    </div>
  </div>;
}

export default Dailies;
