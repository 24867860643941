import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { updateParagraph, createParagraph, deleteParagraph } from './graphql/mutations';
import TextareaAutosize from 'react-textarea-autosize';

function Paragraph({ id, dailyId, body, old }) {
  const [bodyState, setBodyState] = useState(body);
  const [idState, setIdState] = useState(id);
  const [timeoutId, setTimeoutId] = useState(null);

  function updateForm(value) {
    setBodyState(value);
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => saveParagraph(value), 1000));
  }

  async function saveParagraph(value) {
    if (idState) {
      if (!value) {
        await API.graphql(graphqlOperation(deleteParagraph, { input: { id: idState }}))
      } else {
        await API.graphql(graphqlOperation(updateParagraph, {
          input: {
            id: idState,
            body: value
          }
        }));
      }
    } else {
      const paragraphData = await API.graphql(graphqlOperation(createParagraph, {
        input: {
          dailyId,
          body: value
        }
      }));
      setIdState(paragraphData.data.createParagraph.id);
    }
  }

  return <div>
    <TextareaAutosize
      className='paragraph'
      value={bodyState}
      onChange={event => updateForm(event.target.value)}
      minRows={3}
      autoFocus={!old}
    />
  </div>
}

export default Paragraph;